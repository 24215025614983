// Font-Family
.text-inter {
  font-family: "Inter", sans-serif;
}

.text-futura {
  font-family: "Futura";
}

.text-futura-pt {
  font-family: "Futura PT";
}

.text-futura-pt-book {
  font-family: "Futura PT Book";
}
.text-futura-medium {
  font-family: "Futura-medium";
}
.text-proxima-nova {
  font-family: "proxima-nova";
}
.text-proxima-nova-regular {
  font-family: "proxima-nova-regular";
}

// Text Color
.text-raven {
  color: $raven;
}
.text-light-gray {
  color: $light-gray;
}
.text-blue2 {
  color: $blue2;
}
.text-yellow-orange{
  color: $yellow-orange;
}

// Background Color
.bg-pale-bud {
  background-color: $palebud;
}
.bg-white-smoke {
  background-color: $whitesmoke !important;
}

// Font Size
.text-11 {
  font-size: 11px;
}
.text-13 {
  font-size: 13px;
}
.text-15 {
  font-size: 15px;
}
.text-17 {
  font-size: 17px;
}
.text-common {
  font-size: 16.44px;
  letter-spacing: 0.04rem;
}

//Placeholder
.placeholder-dark-50 {
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}

//Modal
.modal-title {
  color: $primary;
  font-family: "Futura";
  font-weight: 500;
  font-size: 1.25rem;
  flex: 1 1;
  text-align: center;
  @include media-breakpoint-up(xl) {
    font-size: 1.75rem;
  }
}

.modal-footer {
  justify-content: center;
  padding-top: 0rem;
}

// Button

.btn-secondary {
  color: $primary;
  &:hover,
  &:active {
    color: $primary;
  }
}

// Form
.form {
  max-width: 324px;
}
.form-control {
  &:-webkit-autofill {
    box-shadow: inset 0 0 20px 20px #fff;
  }
  &:focus,
  &:hover,
  &:active {
    &:-webkit-autofill {
      box-shadow: inset 0 0 20px 20px #fff;
    }
  }
}
.yellow-check {
  .form-check-input {
    &:checked {
      background-color: $secondary;
      border-color: $secondary;
    }
    &:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='5.5' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L3.5 6.5L1 4' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}
//Custom Scroll
.chart-scroll {
  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  scrollbar-color: rgba(255, 255, 255, 0.3) 5 #fff;
  scrollbar-width: 8px;
}

// Remove increment decrement indicator
.text-number {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
}

.payment-method-options {
  .payment-method-description {
    margin-bottom: 4px;
    font-size: 14px;
    color: $raven;
    display: none;
  }
  input {
    &:checked {
      + label {
        + .payment-method-description {
          display: block;
        }
      }
    }
  }
}
.text-inherit {
  text-transform: inherit !important;
}
