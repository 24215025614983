@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@font-face {
  font-family: "Futura";
  src: url(../font/futuramediumbt.woff);
}
@font-face {
  font-family: "Futura-medium";
  src: url(../font/futuramedium.woff);
}
@font-face {
  font-family: "Futura PT";
  src: url(../font/FuturaPTHeavy.woff);
}
@font-face {
  font-family: "Futura PT Book";
  src: url(../font/FuturaPTBook.woff);
}
@font-face {
  font-family: "proxima-nova-regular";
  src: url(../font/ProximaNovaExCn-Light.woff);
}

// Webpixel Bootstrap's Replacement
@import "variable.scss";

@import "custom.scss";
@import "helper.scss";
