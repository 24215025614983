//header
// .dropdown {
//     .dropdown-toggle {
//         &::after {
//             display: none;
//         }
//     }
// }

.nav-tabs {
  .nav-item {
    .nav-link {
      padding-left: $nav-tabs-link-padding-x;
      padding-right: $nav-tabs-link-padding-x;
    }
  }
}

.drop-link {
  text-decoration: none;
  &:hover {
    background-color: $primary;
    color: $light;
    text-decoration: none;
  }
}

.arrow-drop {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

// Session-Card
.session-card {
  min-height: 373px;
}

// Loader

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 999;
}

//React Datepicker
.react-datepicker-wrapper {
  display: block;
  .react-datepicker__calendar-icon {
    padding: 0;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
  }
  .react-datepicker__view-calendar-icon input {
    padding: 7.6px 25px 7.5px 10px;
  }
}

.tooltip-light {
  .tooltip-inner {
    max-width: 280px;
    background-color: #ffffff;
    color: #000;
    opacity: 1;
    padding: 10px 20px;
    text-align: start;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Inter", sans-serif;
  }
  .tooltip-arrow {
    display: none;
  }
}

// select
.input-select {
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 299px;
  overflow-y: auto;
  .input-option {
    font-size: 14px;
    color: #152536;
    padding: 12px 16px;
    &:hover,
    &:active {
      background-color: #152536;
      color: #fff;
    }
  }
}

.react-select__option--is-disabled {
  background-color: transparent !important;
  color: #a8b5c1 !important;
  &:hover {
    background-color: transparent !important;
    color: #a8b5c1 !important ;
  }
}

.team-input-sec {
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    label {
      background-color: #e5dfdc;
    }
    input {
      background-color: #e5dfdc;
    }
  }
  label {
    background-color: $palebud;
  }
  input {
    background-color: $palebud;
    transition: none;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #eeeae8 inset !important;
      &:hover {
        -webkit-box-shadow: 0 0 0 30px #e5dfdc inset !important;
      }
      &:focus {
        -webkit-box-shadow: 0 0 0 30px #e5dfdc inset !important;
      }
      &:active {
        -webkit-box-shadow: 0 0 0 30px #e5dfdc inset !important;
      }
    }
  }
}

//Group Table
.group-table {
  margin-right: calc(-50vw + 50% - -10px);
}

.remove-text {
  &:hover {
    color: #fff;
    background-color: #152536;
  }
}

// Date picker
.session-datepicker {
  .react-datepicker__navigation-icon {
    top: 8px;
  }
}
.remove_key_icon_safari::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
}
// Chart Modal
.chart-modal {
  .nav-pills {
    .nav-link {
      background-color: #fff7e6;
      color: #000;
      font-size: 14.74px;
      font-weight: 600;
      &.active {
        background-color: $secondary;
      }
    }
  }
}

//Team Performance Table
.teamperformance-table {
  color: #000;
  th,
  td {
    background-color: transparent;
    border: none;
  }
  th {
    font-size: 14px !important;
    font-weight: 400 !important;
    &.title {
      font-size: 18px !important;
      font-weight: 500 !important;
      font-family: "Futura-medium";
    }
  }
  .form-control {
    width: 123px;
    background-color: transparent;
  }
  .w-sm {
    width: 123px !important;
    white-space: normal;
    text-align: center;
  }
  .w-xs {
    width: 50px !important;
  }
  .ms-n07 {
    margin-left: -0.07rem;
  }
  .mt-n07 {
    margin-top: -0.06rem;
  }
}
.custom-status-btn {
  min-width: 280px;
}

.foo-bar {
  background-color: #FDCF76;
  color: #000000;
}

.Toastify__close-button {
  color: #000000;
}